<template>
  <div class="max-w-full overflow-hidden h-full bg-black relative">
    <transition-group name="fade">
      <div
        @mousedown="handlemousedown"
        @mouseup="handlemouseup"
        @mousemove="handlemousemove"
        @touchstart="handletouchStart"
        @touchend="handletouchEnd"
        v-for="i in [currentIndex]"
        :key="i"
        class="slide"
        style="
          background-size: cover;
          height: 100%;
          background-repeat: no-repeat;
        "
        :style="{ backgroundImage: `url(${$api}/uploads/${currentImg})` }"
      ></div>
      <!-- <img v-for="i in [currentIndex]" :key="i" :src="currentImg" /> -->
    </transition-group>
    <div class="buttons" v-if="images.length > 1">
      <button
        @click="gotoslide(i - 1)"
        v-for="i in images.length"
        :key="i"
        class="p-1"
      >
        <div
          style="transition: all 0.2s ease-in-out"
          class="w-4 bg-white h-4 rounded-full"
          :class="{
            'opacity-40': currentPosition !== i - 1,
          }"
        ></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: ["images"],
  data() {
    return {
      touchEndX: 0,
      touchendY: 0,
      touchstartX: 0,
      touchstartY: 0,
      mousemoveXStart: 0,
      mousemoveXEnd: 0,
      timer: null,
      currentIndex: 0,
      mouseDown: false,
      mouseMovingInit: true,
    };
  },

  mounted: function () {
    this.startSlide();
  },

  methods: {
    startSlide: function () {
      this.timer = setInterval(this.next, 4000);
    },

    next: function () {
      if (this.images.length > 1) {
        clearInterval(this.timer);
        this.timer = setInterval(this.next, 4000);
        this.currentIndex += 1;
      }
    },
    prev: function () {
      if (this.images.length > 1) {
        clearInterval(this.timer);
        this.timer = setInterval(this.next, 4000);
        this.currentIndex -= 1;
      }
    },
    gotoslide: function (index) {
      clearInterval(this.timer);
      this.timer = setInterval(this.next, 4000);
      this.currentIndex = index;
    },
    handletouchStart(event) {
      this.touchstartX = event.changedTouches[0].screenX;
      this.touchstartY = event.changedTouches[0].screenY;
    },
    handletouchEnd(event) {
      this.touchendX = event.changedTouches[0].screenX;
      this.touchendY = event.changedTouches[0].screenY;
      this.handleGesture();
    },
    handleGesture() {
      if (this.touchendX < this.touchstartX) {
        // console.log("Swiped Left");
        this.prev();
      }

      if (this.touchendX > this.touchstartX) {
        // console.log("Swiped Right");
        this.next();
      }
    },
    handlemousedown() {
      this.mouseDown = true;
    },
    handlemouseup(e) {
      this.mouseDown = false;
      this.mouseMovingInit = true;
      this.mousemoveXEnd = e.screenX;
      if (this.mousemoveXEnd < this.mousemoveXStart) {
        // console.log("Swiped Left");
        this.prev();
      }
      if (this.mousemoveXEnd > this.mousemoveXStart) {
        // console.log("Swiped Right");
        this.next();
      }
    },
    handlemousemove(e) {
      if (this.mouseDown) {
        if (this.mouseMovingInit) {
          this.mousemoveXStart = e.screenX;
          this.mouseMovingInit = false;
        }
      }
    },
  },

  computed: {
    currentImg: function () {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    },
    currentPosition() {
      return Math.abs(this.currentIndex) % this.images.length;
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  overflow: hidden;
  visibility: visible;
  position: absolute;
  width: 100%;
}

.fade-enter,
.fade-leave-to {
  transform: translateX(100%);
  visibility: hidden;
  width: 100%;
  z-index: 999;
}

img {
  position: absolute;
  transition: transform 0.1s ease 0.1s;
}

.slide {
  position: absolute;
  width: 100%;
  transition: all 1s;
}

.buttons {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  bottom: 2.5%;

  &:active {
    border: none;
  }
}

.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: auto;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.7s ease;
  border-radius: 0 4px 4px 0;
  text-decoration: none;
  user-select: none;
}

.next {
  right: 0;
}

.prev {
  left: 0;
}

.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
</style>