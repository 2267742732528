<template>
  <div
    class="fixed w-full bottom-0 z-50 block xs:block sm:block md:hidden lg:hidden xl:hidden 2xl:hidden "
  >
    <div class="sticky-booking">
      <div class="w-1/2 text-sm pl-3 flex items-center justify-start">
        <svg
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
          aria-hidden="true"
          role="presentation"
          focusable="false"
          style="display: block; height: 14px; width: 14px; fill: #FDB10C; margin-right: 4px; "
        >
          <path
            d="M15.094 1.579l-4.124 8.885-9.86 1.27a1 1 0 0 0-.542 1.736l7.293 6.565-1.965 9.852a1 1 0 0 0 1.483 1.061L16 25.951l8.625 4.997a1 1 0 0 0 1.482-1.06l-1.965-9.853 7.293-6.565a1 1 0 0 0-.541-1.735l-9.86-1.271-4.127-8.885a1 1 0 0 0-1.814 0z"
            fill-rule="evenodd"
          ></path>
        </svg>

        5.2 Star Luxury
      </div>

      <div class="w-1/2 flex justify-end">
        <button
          @click="$router.push(`/enquiry?property=${property._id}`)"
          class="sticky-booking-btn"
        >
          Book Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StickyBooking",
};
</script>
